import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import {
  fetchUsers,
  setSubscribedUser,
  setUnsubscribeFromCaptures,
  setUnsubscribeWildcardCapture,
  listenLatestCaptureForAdmin,
  selectUserAndFetchDetails,
  listenAllCapturesForAdmin,
  User,
} from "../../features/admin/adminSlice";
import { Button, Space, Card, AutoComplete, Row, Col } from "antd";
import CaptureViewer from "../CaptureViewer/CaptureViewer";
import { getLaunchAngleText } from "../../utils/launchAngleHelper";

const Admin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [speedUnit, setSpeedUnit] = useState<"mph" | "km/h" | "m/s">("mph");
  const [loading, setLoading] = useState(false);
  const [wildcardMode, setWildcardMode] = useState(false);

  const {
    users,
    subscribedUser,
    unsubscribeFromCaptures,
    unsubscribeWildcardCapture,
    lastWildcardCapture,
  } = useAppSelector((state) => state.admin);
  const subscribedUserLastCapture = subscribedUser?.lastCapture;

  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const isSubscribed = Boolean(subscribedUser || wildcardMode);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleSelect = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (value === "All Users") {
        setSelectedUser(null);
      } else {
        const user = users.find((user) => user.email === value);
        setSelectedUser(user || null);
      }
    },
    [users]
  );

  const handleChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (value !== "All Users") {
        const user = users.find((user) => user.email === value);
        setSelectedUser(user || null);
      } else {
        setSelectedUser(null);
      }
    },
    [users]
  );

  const handleSubscribe = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      // Unsubscribe from any existing subscriptions
      if (unsubscribeFromCaptures) {
        unsubscribeFromCaptures();
        dispatch(setUnsubscribeFromCaptures(null));
      }
      if (unsubscribeWildcardCapture) {
        unsubscribeWildcardCapture();
        dispatch(setUnsubscribeWildcardCapture(null));
      }
      if (searchValue === "All Users") {
        // Subscribe to wildcard captures
        const unsubscribe = await dispatch(
          listenAllCapturesForAdmin()
        ).unwrap();
        dispatch(setUnsubscribeWildcardCapture(unsubscribe));
        setWildcardMode(true);
        dispatch(setSubscribedUser(null));
      } else if (selectedUser) {
        // Subscribe to selected user's captures
        const userWithDetails = await dispatch(
          selectUserAndFetchDetails(selectedUser)
        ).unwrap();
        const unsubscribe = await dispatch(
          listenLatestCaptureForAdmin(selectedUser.uid)
        ).unwrap();
        dispatch(setSubscribedUser(userWithDetails));
        dispatch(setUnsubscribeFromCaptures(unsubscribe));
        setWildcardMode(false);
      } else {
        console.warn("No user selected and not subscribing to 'All Users'");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
    } finally {
      setLoading(false);
    }
  }, [
    loading,
    searchValue,
    selectedUser,
    unsubscribeFromCaptures,
    unsubscribeWildcardCapture,
    dispatch,
  ]);

  const handleUnsubscribe = useCallback(() => {
    if (loading) return;
    setLoading(true);
    try {
      if (unsubscribeFromCaptures) {
        unsubscribeFromCaptures();
        dispatch(setUnsubscribeFromCaptures(null));
      }
      if (unsubscribeWildcardCapture) {
        unsubscribeWildcardCapture();
        dispatch(setUnsubscribeWildcardCapture(null));
      }
      setWildcardMode(false);
      dispatch(setSubscribedUser(null));
      setSelectedUser(null);
      setSearchValue("");
    } catch (error) {
      console.error("Error unsubscribing:", error);
    } finally {
      setLoading(false);
    }
  }, [loading, unsubscribeFromCaptures, unsubscribeWildcardCapture, dispatch]);

  const handleSpeedUnitChange = () => {
    setSpeedUnit((prevUnit) =>
      prevUnit === "mph" ? "km/h" : prevUnit === "km/h" ? "m/s" : "mph"
    );
  };

  const allUsersOption = { value: "All Users" };

  const options = [
    allUsersOption,
    ...users
      .filter((user) => user.email)
      .map((user) => ({ value: user.email })),
  ];

  const captureToDisplay = wildcardMode
    ? lastWildcardCapture
    : subscribedUserLastCapture;

  const renderShotData = () => {
    if (!captureToDisplay) {
      return <div>Waiting for the next shot...</div>;
    }

    return (
      <Card
        title={
          wildcardMode
            ? `Shot Data (User: ${captureToDisplay.userEmail || "Unknown"})`
            : captureToDisplay?.reported
            ? "Shot Data -> Reported"
            : "Shot Data"
        }
        bodyStyle={{ padding: "0" }}
        style={captureToDisplay?.reported ? { backgroundColor: "red" } : {}}
      >
        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              justifyContent: "center",
            }}
          >
            <strong
              style={{ textAlign: "right" }}
              onClick={handleSpeedUnitChange}
            >
              Ball Speed:
            </strong>
            {speedUnit === "mph" && captureToDisplay.ball_speed
              ? (captureToDisplay.ball_speed * 2.237).toFixed(1)
              : speedUnit === "km/h" && captureToDisplay.ball_speed
              ? (captureToDisplay.ball_speed * 3.6).toFixed(1)
              : captureToDisplay.ball_speed?.toFixed(1)}{" "}
            {speedUnit.toLowerCase()}
          </li>
          <li
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              justifyContent: "center",
            }}
          >
            <strong style={{ textAlign: "right" }}>
              Vertical Launch Angle:
            </strong>
            {getLaunchAngleText(captureToDisplay.vertical_launch_angle, true)}
          </li>
          <li
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              justifyContent: "center",
            }}
          >
            <strong style={{ textAlign: "right" }}>
              Horizontal Launch Angle:
            </strong>
            {getLaunchAngleText(captureToDisplay.horizontal_launch_angle)}
          </li>
          <li
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              justifyContent: "center",
            }}
          >
            <strong style={{ textAlign: "right" }}>Spin:</strong>
            {captureToDisplay.spin?.toFixed(0)} (rpm)
          </li>
          <li
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              justifyContent: "center",
            }}
          >
            <strong style={{ textAlign: "right" }}>Spin Axis:</strong>
            {getLaunchAngleText(captureToDisplay.spin_axis)}
          </li>
        </ul>
      </Card>
    );
  };

  return (
    <Row justify="center" style={{ padding: "5px" }}>
      <Col xs={24}>
        <Space
          direction="vertical"
          size={8}
          style={{ width: "100%", minWidth: "100%" }}
        >
          <Space>
            <AutoComplete
              value={searchValue}
              style={{ width: 300 }}
              options={options}
              placeholder="Search for a user or select 'All Users'"
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().includes(inputValue.toUpperCase())
              }
              onSelect={handleSelect}
              onChange={handleChange}
            />
            <Button
              type={isSubscribed ? "default" : "primary"}
              danger={isSubscribed}
              onClick={isSubscribed ? handleUnsubscribe : handleSubscribe}
              disabled={loading || (!searchValue && !isSubscribed)}
            >
              {isSubscribed ? "Unsubscribe" : "Subscribe"}
            </Button>
          </Space>
          {renderShotData()}
          {captureToDisplay && <CaptureViewer lastCapture={captureToDisplay} />}
        </Space>
      </Col>
    </Row>
  );
};

export default Admin;
