// src/features/user/userSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  auth,
  functions,
  googleProvider,
  appleProvider,
  db,
} from "../../app/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  signInWithCustomToken,
  UserCredential,
} from "firebase/auth";
import { clearUnsubscribeFunctions } from "../../utils/unsubscribeManager";
import { AppDispatch } from "../../app/store";
import { httpsCallable } from "firebase/functions";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

interface ProviderData {
  providerId: string;
  uid: string;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
}

interface User {
  uid: string | undefined;
  email: string | undefined;
  emailVerified: boolean | undefined;
  isAnonymous: boolean | undefined;
  providerData: ProviderData[];
  admin: boolean | undefined;
}

interface VerifyTokenResponse {
  customToken: string;
}

interface UserState {
  currentUser: User | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  currentUser: null,
  isLoading: false,
  error: null,
};

export const login =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      await dispatch(processUserCredential(userCredential));
    } catch (error: unknown) {
      dispatch(handleError(error));
      dispatch(setLoading(false));
    }
  };

export const signInWithGoogle = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await signInWithPopup(auth, googleProvider);
    if (!result || !result.user) {
      throw new Error("No user returned from Google sign-in");
    }
    await dispatch(processUserCredential(result));
  } catch (error: unknown) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
  }
};

// New Action for Apple Sign-In
export const signInWithApple = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await signInWithPopup(auth, appleProvider);
    if (!result || !result.user) {
      throw new Error("No user returned from Apple sign-in");
    }
    await dispatch(processUserCredential(result));
  } catch (error: unknown) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
  }
};

export const authenticateWithToken = createAsyncThunk(
  "user/authenticateWithToken",
  async (idToken: string, { rejectWithValue, dispatch }) => {
    try {
      const verifyToken = httpsCallable<{ token: string }, VerifyTokenResponse>(
        functions,
        "verifytoken"
      );
      const response = await verifyToken({ token: idToken });
      const customToken = response.data.customToken; // Assuming the cloud function returns a custom token

      const userCredential = await signInWithCustomToken(auth, customToken);

      const userData = await dispatch(processUserCredential(userCredential));

      return userData;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
    }
  }
);
const processUserCredential = createAsyncThunk(
  "user/processUserCredential",
  async (userCredential: UserCredential, { rejectWithValue }) => {
    try {
      const { uid, email, emailVerified, isAnonymous, providerData } =
        userCredential.user;

      if (!uid) {
        throw new Error("User UID is undefined.");
      }

      const idTokenResult = await userCredential.user.getIdTokenResult(true);
      const admin = idTokenResult.claims.admin === true;

      let docId: string | undefined = uid;

      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        const legacyUserRef = doc(db, "development", "legacy_user_ids");
        const legacyUserSnap = await getDoc(legacyUserRef);

        if (!legacyUserSnap.exists()) {
          throw new Error("Legacy mapping document not found.");
        }

        const legacyData = legacyUserSnap.data();
        if (!legacyData || !(uid in legacyData)) {
          throw new Error(`UID ${uid} not found in legacy mapping.`);
        }

        const mappedUid = legacyData[uid] as string;
        docId = mappedUid;

        const mappedUserDocRef = doc(db, "users", docId);
        const mappedUserDocSnap = await getDoc(mappedUserDocRef);

        if (!mappedUserDocSnap.exists()) {
          throw new Error("User document not found in Firestore");
        }
      }

      if (!docId) {
        throw new Error("Document ID is undefined.");
      }

      return {
        uid: docId,
        email: email || "",
        emailVerified,
        isAnonymous,
        providerData,
        admin,
      } as User;
    } catch (error: unknown) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
    }
  }
);

export const handleError = (error: unknown) => (dispatch: AppDispatch) => {
  if (error instanceof Error) {
    dispatch(setError(error.message));
  } else {
    dispatch(setError("Unknown error occurred"));
  }
};

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    clearUnsubscribeFunctions();
    await signOut(auth);
    dispatch(setCurrentUser(null));
    dispatch(setLoading(false));
  } catch (error: unknown) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
  }
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateWithToken.fulfilled, (state, action) => {
        const user = action.payload as unknown as User;
        state.currentUser = user;
        state.isLoading = false;
      })
      .addCase(authenticateWithToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(processUserCredential.fulfilled, (state, action) => {
        const user = action.payload as User;
        state.currentUser = user;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(processUserCredential.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setCurrentUser, setLoading, setError, clearError } =
  userSlice.actions;

export default userSlice.reducer;
